.BtnSingIn{
    margin-right: 50px;
    width: 10rem;
    height: 3rem;
    border-radius: 5px;
    background-color: rgb(255, 167, 66);
    border: none;
    font-size: 20px;
    color: rgb(255, 255, 255);
    cursor: pointer;
}
.BtnSingIn:hover{
    background-color: rgb(255, 168, 68)

}