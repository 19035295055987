section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #ffa84400;
}


section .color {
  position: absolute;
  filter: blur(150px);
}

section .color:nth-child(1) {
  top: -350px;
  width: 600px;
  height: 600px;

}

section .color:nth-child(2) {
  top: 150px;
  left: -155px;
  width: 120px;
  height: 120px;
  z-index: 2;
}

section .color:nth-child(3) {
  bottom: 50px;
  right: 100px;
  width: 300px;
  height: 300px;
}

.jk {
  position: relative;
}

.jk .square {
  position: absolute;
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  animation: animate 10s linear infinite;
  animation-delay: calc(-1s * var(--i));
}

@keyframes animate {

  0%,
  100% {
      transform: translateY(-40px);
  }

  50% {
      transform: translateY(40px);
  }
}

.jk .square:nth-child(1) {
  top: -50px;
  right: -95px;
  width: 100px;
  height: 100px;
}

.jk .square:nth-child(2) {
  top: 150px;
  left: -125px;
  width: 120px;
  height: 120px;
  z-index: 2;
}

.jk .square:nth-child(3) {
  bottom: 50px;
  right: -85px;
  width: 80px;
  height: 80px;
  z-index: 2;
}


.jk .square:nth-child(4) {
  bottom: -80px;
  left: 100px;
  width: 50px;
  height: 50px;
}

.jk .square:nth-child(5) {
  top: -80px;
  left: 100px;
  width: 60px;
  height: 60px;
}

.container{
  width: 1000px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.container .card{
  position: relative;
}

.container .card .face{
  width: 300px;
  height: 200px;
  transition: 0.5s;
}

.container .card .face.face1{
  position: relative;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
}

.container .card:hover .face.face1{
  background: #ffa844;
  transform: translateY(0px);
}

.container .card .face.face1 .content{
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 0.2;
  transition: 0.5s;
}

.container .card:hover .face.face1 .content{
  opacity: 1;
}

.container .card .face.face1 .content .imgface{
  max-width: 100px;
}

.container .card .face.face1 .content .h3face{
  margin: 10px 0 0;
  padding: 0;
  color: #fff;
  align-items: center;
  font-size: 1.5em;
}

.container .card .face.face2{
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(0,0,0,0.8);
  transform: translateY(-100px);
}


.container .card:hover .face.face2{
  transform: translateY(0px);
}

.container .card .face.face2 .content .pface{
  margin: 0;
  margin: 0;
}

.container .card .face.face2 .content .boton {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 40px;
	background: #333;
	color: #fff;
  margin: 20px 0 0;
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	font-weight: 500;
	border: none;
	cursor: pointer;

	transition: .3s ease all;
	border-radius: 5px;
	position: relative;
	overflow: hidden;
}

.container .card .face.face2 .content .boton .spanface{
	position: relative;
	z-index: 2;
	transition: .3s ease all;
}


.container .card .face.face2 .content .boton.buttons::after {
	content: "";
	width: 1px;
	height: 1px;
	background: none;
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	transition: .3s ease-in-out all;
	border-radius: 100px;
	transform-origin: center;
}

.container .card .face.face2 .content .boton.buttons:hover::after {
	transform: scale(400);
	background: #ffa844;
}

.iconX> a ion-icon{
  font-size: 30px;
  cursor: pointer;
  color: black;
}

.iconX{
  position: fixed;
  top: 0;
  right: 0;
  padding-left: 20px;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .container .card {   
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
