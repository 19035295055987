.carrito{
    width:100%;
    height: 90vh;
    background-color:#ffffff;
    /* position: fixed; */
    /* z-index: 1; */
    /* top: 80px; */
}

.titleCarrito{
    margin-left:20px;
    font-size: 30px;
}

.itemsCarrito{
    width: 100%;
    min-height: 320px;
    height: auto;
    max-height: 65vh;
    overflow:auto;
    padding: 10px 0;
}

.itemsCarrito::-webkit-scrollbar-thumb {
    background-color: #20202000;
    border-radius: 20px;
}

.itemCarrito{
    width: 100vw;
    height: 130px;
    display: flex;
    padding: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 0px 4px #d4d4d4;
    gap: 10px;
    margin-top: 8px;
}

.imgItem{
    width: 25%;
    height: 110px;
    border-radius: 10px;
}

.infoItem{
    width: 100%;
    height: auto;
}

.infoItem p{
    color: #464646;
    font-weight: 500;
    max-width: 100%;
}

.name{
    max-width: 90%;
    height: auto;
    max-height: 35px ;
    color: #ffa500;
    overflow: hidden;
    margin-bottom: 8px;
}
/* 
.description{
    width: 80%;
    height: auto;
    max-height: 45px ;
    margin-bottom: 4px;
} */

.btnDelete{
    position: relative;
    /* top: -35px; */
    right:-220px;
    font-size: 23px;
    cursor: pointer;
    color: #ffa500;
    width: 20%;
    transition: all 0.2s;
}

.btnDelete:hover{
    transform: rotate(10deg);
    color: #272727;
}

.quantity{
    width: 40px;
    height: 20px;
    margin-left: 5px;
    border: none;
}

.total{
    padding:5px 15px;
    width:100%;
    display: flex;
    justify-content:space-between;
}

.total> h4{
    color: #4d4d4d;
}

.infoItem p span{
    color: #2e2e2e;
}

.itemsAd{
    width: 100%;
    text-align: center;
    font-size: 30px;
    margin-top:10px ;
    color: #fda623;
}

.infoPay{
    padding-left:15px;
    color: #929292;
}

.button {
    border: none;
    margin:20px 0 0 70px;
    width: 260px;
    height: 28px;
    border-radius: 5px;
    font-weight: bold;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #ffcf32;
    transition: all 1000ms;
    font-size: 15px;
    overflow: hidden;
    outline: 2px solid #fda623;
    background-color: #ffffff;
  }
  
.button:hover {
    color: #ffffff;
    transform: scale(1.1);
    outline: 2px solid #ffcc23;
    box-shadow: 4px 5px 17px -4px #ffb923;
    cursor: pointer;
}
  
.button::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #ffc421;
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
}
  
.button:hover::before {
    width: 250%;
}

@keyframes show {
0%{
    transform: translateY(-1000px)
}
100%{
    transform: translateX(0px)

}}

.wheel-and-hamster {
    --dur: 1s;
    position: relative;
    margin: 0 auto;
    width: 170px;
    height: 170px;
    font-size: 14px;
}
  
.wheel,
.hamster,
.hamster div,
.spoke {
    position: absolute;
}
  
.wheel,
.spoke {
    border-radius: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
  
.wheel {
    background: radial-gradient(100% 100% at center,#99999900 47.8%,#999999 48%);
    z-index: 2;
}
  
.hamster {
    animation: hamster var(--dur) ease-in-out infinite;
    top: 50%;
    left: calc(50% - 3.5em);
    width: 7em;
    height: 3.75em;
    transform: rotate(4deg) translate(-0.8em,1.85em);
    transform-origin: 50% 0;
    z-index: 1;
}

.hamster__head {
    animation: hamsterHead var(--dur) ease-in-out infinite;
    background: #f48c25;
    border-radius: 70% 30% 0 100% / 40% 25% 25% 60%;
    box-shadow: 0 -0.25em 0 #facc9e inset,
            0.75em -1.55em 0 #fce6cf inset;
    top: 0;
    left: -2em;
    width: 2.75em;
    height: 2.5em;
    transform-origin: 100% 50%;
}
  
.hamster__ear {
    animation: hamsterEar var(--dur) ease-in-out infinite;
    background: #fbb6b6;
    border-radius: 50%;
    box-shadow: -0.25em 0 #f48c25 inset;
    top: -0.25em;
    right: -0.25em;
    width: 0.75em;
    height: 0.75em;
    transform-origin: 50% 75%;
}
  
.hamster__eye {
    animation: hamsterEye var(--dur) linear infinite;
    background-color: #000000;
    border-radius: 50%;
    top: 0.375em;
    left: 1.25em;
    width: 0.5em;
    height: 0.5em;
}

.hamster__nose {
    background: #f98686;
    border-radius: 35% 65% 85% 15% / 70% 50% 50% 30%;
    top: 0.75em;
    left: 0;
    width: 0.2em;
    height: 0.25em;
}
  
.hamster__body {
    animation: hamsterBody var(--dur) ease-in-out infinite;
    background: #fce6cf;
    border-radius: 50% 30% 50% 30% / 15% 60% 40% 40%;
    box-shadow: 0.1em 0.75em 0 #f48c25 inset,
            0.15em -0.5em 0 #facc9e inset;
    top: 0.25em;
    left: 2em;
    width: 4.5em;
    height: 3em;
    transform-origin: 17% 50%;
    transform-style: preserve-3d;
}

.hamster__limb--fr,
.hamster__limb--fl {
    clip-path: polygon(0 0,100% 0,70% 80%,60% 100%,0% 100%,40% 80%);
    top: 2em;
    left: 0.5em;
    width: 1em;
    height: 1.5em;
    transform-origin: 50% 0;
}
  
.hamster__limb--fr {
    animation: hamsterFRLimb var(--dur) linear infinite;
    background: linear-gradient(#facc9e 80%,#f98686 80%);
    transform: rotate(15deg) translateZ(-1px);
}

.hamster__limb--fl {
    animation: hamsterFLLimb var(--dur) linear infinite;
    background: linear-gradient(#fce6cf 80%,#fbb6b6 80%);
    transform: rotate(15deg);
}

.hamster__limb--br,
.hamster__limb--bl {
    border-radius: 0.75em 0.75em 0 0;
    clip-path: polygon(0 0,100% 0,100% 30%,70% 90%,70% 100%,30% 100%,40% 90%,0% 30%);
    top: 1em;
    left: 2.8em;
    width: 1.5em;
    height: 2.5em;
    transform-origin: 50% 30%;
}
  
.hamster__limb--br {
    animation: hamsterBRLimb var(--dur) linear infinite;
    background: linear-gradient(#facc9e 90%,#f98686 90%);
    transform: rotate(-25deg) translateZ(-1px);
}

.hamster__limb--bl {
    animation: hamsterBLLimb var(--dur) linear infinite;
    background: linear-gradient(#fce6cf 90%,#fbb6b6 90%);
    transform: rotate(-25deg);
}

.hamster__tail {
    animation: hamsterTail var(--dur) linear infinite;
    background: #fbb6b6;
    border-radius: 0.25em 50% 50% 0.25em;
    box-shadow: 0 -0.2em 0 #f98686;
    top: 1.5em;
    right: -0.5em;
    width: 1em;
    height: 0.5em;
    transform: rotate(30deg) translateZ(-1px);
    transform-origin: 0.25em 0.25em;
}
  
.spoke {
    animation: spoke var(--dur) linear infinite;
    background: radial-gradient(100% 100% at center,#999999 4.8%,#99999900 5%),
                linear-gradient(#8c8c8c00 46.9%,#a6a6a6 47% 52.9%,#a6a6a600 53%) 50% 50% / 99% 99% no-repeat;
}

/* Animations */
@keyframes hamster {
from, to {
    transform: rotate(4deg) translate(-0.8em,1.85em);
}
50% {
    transform: rotate(0) translate(-0.8em,1.85em);
}}

@keyframes hamsterHead {
from, 25%, 50%, 75%, to {
    transform: rotate(0);
}
12.5%, 37.5%, 62.5%, 87.5% {
    transform: rotate(8deg);
}}

@keyframes hamsterEye {
from, 90%, to {
    transform: scaleY(1);
}
95% {
    transform: scaleY(0);
}}
  
@keyframes hamsterEar {
from, 25%, 50%, 75%, to {
    transform: rotate(0);
}
12.5%, 37.5%, 62.5%, 87.5% {
    transform: rotate(12deg);
}}

@keyframes hamsterBody {
from, 25%, 50%, 75%, to {
    transform: rotate(0);
}
12.5%, 37.5%, 62.5%, 87.5% {
    transform: rotate(-2deg);
}}
  
  @keyframes hamsterFRLimb {
from, 25%, 50%, 75%, to {
    transform: rotate(50deg) translateZ(-1px);
}
12.5%, 37.5%, 62.5%, 87.5% {
    transform: rotate(-30deg) translateZ(-1px);
}}

@keyframes hamsterFLLimb {
from, 25%, 50%, 75%, to {
    transform: rotate(-30deg);
}
12.5%, 37.5%, 62.5%, 87.5% {
    transform: rotate(50deg);
}}
  
@keyframes hamsterBRLimb {
from, 25%, 50%, 75%, to {
    transform: rotate(-60deg) translateZ(-1px);
}
12.5%, 37.5%, 62.5%, 87.5% {
    transform: rotate(20deg) translateZ(-1px);
}}

@keyframes hamsterBLLimb {
from, 25%, 50%, 75%, to {
    transform: rotate(20deg);
}
12.5%, 37.5%, 62.5%, 87.5% {
    transform: rotate(-60deg);
}}
  
@keyframes hamsterTail {
from, 25%, 50%, 75%, to {
    transform: rotate(30deg) translateZ(-1px);
}
12.5%, 37.5%, 62.5%, 87.5% {
    transform: rotate(10deg) translateZ(-1px);
}}
  
@keyframes spoke {
from {
    transform: rotate(0);
}
to {
    transform: rotate(-1turn);
}}