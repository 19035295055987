.containerForm {
  width: 100%;
  height: 110vh;
  position: fixed;
  top: 100px;
  left: 0;
  background: radial-gradient( at center bottom,rgb(77, 77, 77),rgb(36, 36, 36));
  margin-bottom: 50px;
  z-index: 3;
}

.containerClose {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 3;
}

.iconCLose {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
}

.form {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  top: 55px;
  right: 0;
  gap: 3px;
  color: #fff;
  z-index: 2;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.input,
.textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.1rem;
}

.container{
  display: flex;
  justify-content: center;
}

.containerFile{
  height: 150px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ffa844;
  cursor: pointer;
  border-radius: 5px;
}

.imagePreview {
  position: relative;
}

.imgPreview {
  display: inline-block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  height: 50px;
  width: 80%;
  padding: 10px 20px;
  background-color: #1b53b9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.id{
  display: none;
}