.containerMain{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f5f5;
}

.containerCarta{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: auto;
    background-color: #f8f5f5;
}

.titleMenu{
    font-size: 100%;
    font-size: 1.5rem;
    color: #d6b877;
    display: flex;
    justify-content: center;
    margin: 15px;
}

.containerProducts{
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
}

/* .breakfast {
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
} */

.breakfast1 {
    width: 100%;
    height: 150px;
    display: flex; 
    /* background-color: aliceblue; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    margin-top: 2px;
    align-items: center;
    justify-content: center;
    border:3px dashed rgb(250, 124, 22);
}

.icon .img{
    filter: brightness(70%);
    transition: all 0.4s;
    cursor: pointer;
}
.icon:hover .img{
    transform: scale(1.1);
    filter: brightness(100%);
}

.img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.type{
    font-size: 1rem;
    color: #d6b877;
    bottom: 77px;
}