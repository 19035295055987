* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-decoration: none;
  

}
body{/*
  * Created with https://www.css-gradient.com
  * Gradient link: https://www.css-gradient.com/?c1=343434&c2=010101&gt=r&gd=dbb
  */
  background: rgba(52, 52, 52, 1.0);
  background: radial-gradient(at center bottom, rgb(77, 77, 77), rgb(36, 36, 36));
  background-image: url('./peak_background.svg');
  background-size: cover;
  overflow-x: hidden;
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: rgba(52, 52, 52, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(255, 216, 40);
  border-radius: 20px;
  
}


