.form {
  width: 100%;
  height: 650px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  top: 35px;
  margin: auto;
  gap: 3px;
  color: #fff;
  z-index: 2;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.input,
.textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button {
  padding: 10px 20px;
  height: 50px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: #45a049;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.id{
  display: none;
}