.modal {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modalHeader{
    display:flex;
    height: 65px;
    width: 100%;
    justify-content:space-between;
    align-items: center;

  }
  .imgChat{
    width: 55px;
    height: 55px;
    border-radius:50% /* 30% 70% 70% 30% / 30% 30% 70% 70% */ ;
    background-color: orange;

  }
  .titleChat{
    font-size:25px;
    color: rgb(250, 164, 35)
  }
  
  .modalContent {
    background-color: #303030;
    padding: 15px;
    border-radius: 5px;
    max-width: 450px;
    height: 80vh;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
  .containerMessage{
    height: 355px;
    max-height: 355px;
    overflow: auto;
    margin-bottom: 18px;
    background-color: rgb(70, 70, 70);
    width: 100%;
    border-radius: 5px;
    padding: 15px;
    box-sizing: border-box;
  }
  .messagePurchase{
    text-align: center;
    width: 100%;
    height: auto;
    margin:10px 0 ;
    border-radius: 8px;
    padding: 5px;
    background-color: rgb(53, 53, 53);
    
  }
  .messagePurchase>h3{
    border-bottom: 1px solid white;
    margin-bottom: 5px;
    color: rgb(250, 164, 35);
  }

  .messagePurchase>h4{
    border-top: 1px solid white;
    margin-top: 5px;
    color: rgb(250, 164, 35);
  }
  .messagePurchase>h4>span{
    color: aliceblue;
  }
  .containerItems{
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

  }
  .messageItem{
    width: 100%;
    margin-top: 5px;
    background-color: rgba(255, 0, 0, 0);
    display: flex;
  }
  .messageItem>img{
    width: 75px;
    border-radius: 84% 16% 70% 30% / 34% 72% 28% 66% ;
    height: 65px;
  }
  .messageItem>p{
    width: 25%;
    padding-left:5px ;
    color: rgb(250, 164, 35);
  }
  .messageItem>p>span{
    color: aliceblue;
    max-width: 100%;
    overflow: hidden;
  }
  .messageOne{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    text-align: end;
    
  }
  
  .messageOne>.message{
    width: auto;
    min-width: 40%;
    max-width: 75%;
    border-top-left-radius:8px ;
    border-top-right-radius:8px ;
    border-bottom-Right-radius:8px ;
    height: auto;
    padding: 8px 10px;
    margin-top: 5px;
    background-color: rgb(250, 164, 35);
    font-size: 15px;
    

  }
  .messageText{
    text-align:start;
    color: rgb(59, 59, 59);
  }
  .messageTextTwo{
    text-align:end;
  }
 .dateMessage{
    color: #eeeeee;
    font-size: 9px;  
    position: relative;
    

  }

  .messageTwo>.message{
    width: auto;
    min-width: 40%;
    max-width: 75%;
    border-top-left-radius:8px ;
    border-top-right-radius:8px ;
    border-bottom-left-radius:8px ;
    height: auto;
    background-color: rgb(48, 48, 48);
    padding: 8px 10px;
    color: aliceblue;
   

  }
  .messageTwo{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    gap: 10px;
    text-align: start;
   
  }
  .form{
    width: 100%;
    display: flex;
    gap: 15px;
    
  }
  .closeBtn{
    width: 40px;
    height: 40px;
    background-color:rgba(255, 255, 255, 0);
    font-size: 35px;
    margin:-30px -10px 0 0;
    border: none;
    cursor: pointer;
    color: #eeeeee;
  }
  .closeBtn:hover{
    color: rgb(250, 164, 35);
  }
  
 
  
  .textarea {
    width: 90%;
    border-radius: 20px;
    height: 50px;
    padding: 12px;
    resize: none;
    background-color: #f1efef;
    margin-bottom: 10px;
    font-size: 17px;
    border: none;
    color: rgb(53, 53, 53);
    overflow: hidden;
    outline: none;

  }
  .textarea:focus{
    outline: 2px solid rgb(250, 164, 35);
  }
  .submitBtn{
    height: 50px;
    width: 55px;
    border-radius: 50%;
    background-color: rgb(204, 204, 204);
    border: none;
    font-size: 20px;
    font-size: 25px;
    cursor: pointer;
    max-width: 50px;
    max-height: 50px;
    overflow: hidden;
    transition: all 0.3s
  }
  .submitBtn:hover{
    color: rgb(250, 164, 35);
    background-color: #222222;
  }

 
  
