.carousel {
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 52, 1.0);
  position: fixed;
  top: 100px;
  z-index: 2;
}

.containerClose {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.iconCLose {
  width: 50px;
  height: 50px;
  position: absolute;
  color: #fff;
  right: 10;
}

.productImage {
  width: 100%;
  height: 16em;
  object-fit: cover;
}

.card {
  width: 98%;
  height: auto;
  gap: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 320px;
  margin: 10px auto;
  margin-top: 100px;
  text-align: center;
  font-family: arial;
  color: #fff;
}

.price {
  /* color: grey; */
  padding: 10px;
  font-size: 22px;
}

.description{
  font-size: 1.3rem;
}

.card button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #ce0404;
  text-align: center;
  cursor: pointer;
  width: 50%;
  font-size: 18px;
  margin-top: 40px ;
}
