.card {
    width: 220px;
    height: 284px;
    padding: .8em;
    background: #ffffff;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 10px;
   }
   
   .card-img {
    background-color: #ececec;
    height: 122px;
    width: 100%;
    border-radius: .5rem;
    transition: .3s ease;
   }
   .cardProduct{
    border-radius: 10px;
    width: 100%;
    height: 100%;
   }
   
   .card-info {
    padding-top: 10px;
    
    

   }
   
 
   
   .card-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid #ddd;
   }
   
   /*Text*/
   .text-title {
    font-weight: 900;
    font-size: 1.2em;
    line-height: 1.5;
    max-height: 55px;
    overflow: hidden;
    
   }
   
   .text-body {
    font-size: .9em;
    padding-bottom: 10px;
    height:35px;
    max-height: 40px;
    overflow: hidden;
    transition: all 0.5s
    
   }
   .text-body:hover{
    height:100px;
    max-height: 100px;
   }
   
   /*Button*/
   .card-button {
    border: 1px solid #252525;
    display: flex;
    padding: .3em;
    cursor: pointer;
    border-radius: 50px;
    transition: .3s ease-in-out;
    font-size: 20px;
   }
   
   /*Hover*/
   .card-img:hover {
    transform: translateY(-8%);
    position: relative;
    z-index: 5;

    box-shadow: 0px 2px 8px rgba(128, 128, 128, 0.418);

   }
   
   .card-button:hover {
    border: 1px solid rgb(255, 216, 41);
    background-color: rgb(255, 206, 47);
    color: white;
    transform: scale(1.1);
   }