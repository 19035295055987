.header{
    width: 100vw;
    height: 100px;
    background-color: rgba(34, 34, 34, 0.692);
    display: flex;
    font-family: "Abril Fatface","Times New Roman",Times,serif;
    position:fixed;
    z-index: 5;
    

}
.logoHeader{
    width:25%;
    height: 100%;
    display: flex;
    overflow: hidden;
    
    align-items: center;
  
}
.logoHeader a img{
    width:110px;
    height: 100px;
    cursor: pointer;

    
}
.logoHeader h1{
    width:38%;
    color: #eeeeee;
    border-right: solid rgb(255, 158, 31) ;
    padding:0 0%;

}
