 .divlogo{
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 20px;
}

.divlogo h2 {
    color: #ffb61a;
    margin-right: 10px;;
}
  
.imglogo {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 1px solid #ffb61a;
    object-fit: cover;
}
  
.menuContainer {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 450px; /* Ajusta el ancho deseado */
    background-color: rgba(34, 34, 34, 0.692);
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(248, 238, 238, 0.2);
    opacity: 0;
    color: #ffa844;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
}

.menuContainer .menuUser .itemMenu{
    font-size: large;
}

.menuUser {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
  
.itemMenu {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ffa844;
    color: #ffa844;
    transition: all 0.3s;
}
  
.itemMenu:hover {
    color: white;
    background-color: #ffa844;
}
  
.divlogo:hover .menuContainer {
    opacity: 1;
    visibility: visible;
}
