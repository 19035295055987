.header {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 4;
    background: rgba(52, 52, 52, 1.0);
    background: radial-gradient(at center bottom, rgb(77, 77, 77), rgb(36, 36, 36));
  }
  
  .nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .containerLogoTitle {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .logo {
    width: 80px;
    height: 80px;
    margin: 0px 8px;
    border-radius: 50%;
  }
  
  .title {
    width: 200px;
    color: #fff;
    font-size: 20px;
  }
  
  .containerMenu {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    margin-right:  10px;
    transition: .5s ease-in-out;
  }
  
  .iconMenu {
    width: 45px;
    height: 45px;
    margin-right: 10px;
    color: #fff;
  }
  
  .menu{
    width: 100%;
    height: 110vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    z-index: 3;
    position: fixed;
    background: rgba(52, 52, 52, 1.0);
    overflow: hidden;
  }
  
  .ul{
    list-style: none;
  }
  
  .li{
    display: flex;
    align-items: center;
    margin: 80px 0;
    gap: 20px;
  }
  
  .link{
    text-decoration: none;
    font-weight: 600;
    font-size: 1.7rem;
    color: #fff;
  }
  