
.whatsapp {
  border-radius:10px;
  position:fixed;
  z-index: 1;
  bottom: 20px;
  right: 20px;
  cursor: pointer;

}


.whatsapp:before,
.whatsapp:after{
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  bottom: 5px;
  right: 0px;
  opacity: 0;
  background-color: rgb(50, 255, 136);
  animation: onda 1.5s infinite;
}
.whatsapp:before{
  animation-delay: 1s;
}
.whatsapp:after{
  animation-delay: 1.3s;
}
.whatsappImg{
  position: relative;
  width: 60px;
  height: 60px;
  z-index: 2;
 
}
@keyframes onda {
  0%{
      transform: scale(1);

  }
  15%{
      opacity: 1;

  }
  100%{
      transform: scale(1.5);
      opacity: 0;
  }
}
.imgInicio{
  margin:0px auto 0px;
  width: 100%;
  height: 70vh;
  background-image: url('https://i.pinimg.com/originals/d6/49/48/d64948463ed288a6105c49177316ef1e.jpg');
  background-size: cover;
  border-bottom-left-radius:100px;
  border-bottom-right-radius: 100px;
  
 
}
.button {
  border: none;
  margin:20px 0 0 0px;
  width: 260px;
  height: 38px;
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #ffcf32;
  transition: all 1000ms;
  font-size: 15px;
  overflow: hidden;
  outline: 2px solid #fda623;
  background-color: #ffffff;
}

.button:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid #ffcc23;
  box-shadow: 4px 5px 17px -4px #ffb923;
  cursor: pointer;
}

.button::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #ffc421;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}

.button:hover::before {
  width: 250%;
}
@keyframes show {
  0%{
      transform: translateY(-1000px)
  }
  100%{
      transform: translateX(0px)

  }
  
}
.filtro{
  width: 100%;
  height: 100%;
  background-color:rgba(0, 0, 0, 0.527) ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius:100px;
  border-bottom-right-radius: 100px;
}
.h2{
  color: orange;
}
.h3{
  width: 70%;
  font-size: 25px;
  text-align: center;
  padding-bottom:15px ;
}
.filtro{
  font-size:25px;
  color: azure;
}


.infoGray{
    width: 100%;
    height: 800px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px;
    gap: 20px;;
    
}
.infoTitle{
  width: 100%;
  text-align: center;
  color: #2e2d31;
  font-size: 50px;
}
.itemInfo{
  width: 300px;
  height: 400px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  padding: 40px 10px;
  text-align: center;
  box-shadow: 0px 4px 28px 2px rgb(190, 190, 190);
}
.itemInfo>h2{
  color: #fda623;
}
.itemInfo>p{
  font-size: 18px;
  color: #636363;

}
.itemImg{
  width: 100px;
  margin: 0 auto;
  height: 80px;
}

.containerPlaces{
    width: 100%;
    margin-top: 40px ;
   
    height:auto;
    margin-bottom: 20px;
}
.containerPlaces h2{
    width: 80%;
    font-size: 35px;
    padding-left:10px ;
    color: rgb(255, 255, 255);
    border-top: solid rgb(65, 65, 65);
    margin: 0 auto;
}
.containerPlaces h4{
    width: 80%;
    font-size: 25px;
    color: rgb(221, 221, 221);
    padding-left:10px ;
    margin: 0 auto;
}

.containerItems{
    margin: 60px auto;
    width: 94%;
    height: auto;
    display: flex;
    justify-content:space-between;
    padding: 5px;
   
    flex-wrap: wrap;
    padding: 10px;
}
.item{
    width: 23%;
    height: auto;

    padding: 8px;
    margin-top: 10px;
    transition: all 0.6s;
    cursor: pointer;
    border-radius: 20px;
    text-align: center;   
}
.BtnEmpieza{
  margin-right: 50px;
  width: 15rem;
  height: 3rem;
  border-radius: 5px;
  background-color: rgb(255, 167, 66);
  border: none;
  font-size: 20px;
  color: rgb(255, 255, 255);
  cursor: pointer;
}
.BtnEmpieza:hover{
  background-color: rgb(255, 168, 68)

}
.item:hover{
   
    box-shadow: 0px 3px 8px  rgba(41, 41, 41, 0.534);
    transform: scale(1.01);
    background-color: rgb(253, 189, 69);
}



.card {
  position: relative;
  width: 270px;
  height: 270px;
  color: #2e2d31;
  background: #131313;
  overflow: hidden;
  border-radius: 20px;
  cursor: pointer;
  

}

.imgCard{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card_title {
  font-weight: bold;
}

.card_content {
  position: absolute;
  left: 0;
  bottom: 0;
    /* edit the width to fit card */
  width: 100%;
  padding: 10px 20px;
  background: #ffffff;
  border-top-left-radius: 20px;
  
    /* edit here to change the height of the content box */
  transform: translateY(140px);
  transition: transform .5s;
}

.card_content::before {
  content: '';
  position: absolute;
  top: -47px;
  right: -45px;
  width: 100px;
  height: 100px;
  transform: rotate(-175deg);
  border-radius: 50%;
  box-shadow: inset 48px 48px #ffffff;
}

.card_title {
  color: #fdb122;
  font-size: 20px;
  max-width: 90%;
  max-height: 45px;
  line-height: 10px;
  margin-bottom: 8px;
}

.card_subtitle {
  display: block;
  font-size: 12px;
  color: #7a7a7a;
  margin-bottom: 10px;
}

.card_description {
  font-size: 14px;
  opacity: 0;
  max-width: 90%;
  min-height: 130px;
  transition: opacity .5s;
  color: #3f3f3f;
}

.card:hover .card_content {
  transform: translateY(0);
}

.card:hover .card_description {
  opacity: 1;
  transition-delay: .25s;
}
.containerMenu{
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.containerMenu>h2{
  font-size: 45px;
  color: rgb(53, 53, 53);
}
.brands{
  width: 70%;
  height: auto;
  display: flex;
  overflow: hidden;
  padding: 20px;

}
.carusel{
  display: flex;
  gap: 55px;
  width: max-content;
  animation: carousel-animation 18s infinite linear ;

}
.brand{
  width: 200px;
  height: 200px;
  padding: 20px;
  border-radius: 20px;
  background-color: #ffffff;
 
}
.brand>img{
 width: 100%;
 height: 100%;
}
 @keyframes carousel-animation{
  0% {
    transform: translateX(-52%);
  }
  

  100% {
    transform: translateX(-1%);
  }
  
} 





.containerSubs{
  margin-top: 100px;
  background-image: url('./patterns.svg');
   width: 100%;
   height:500px;
   padding: 80px;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   gap:80px;
}
.sub:nth-child(2){
  transform: scale(1.15);
}

.sub{
   width: 20%;
   height:350px;
   background-color:rgb(255, 255, 255);
   border-radius: 10px;
   text-align: center;
 
   transition: all 0.2s;
}

.headerSub{
   width: 100%;
   height:auto;
   border-top-left-radius:10px ;
   border-top-right-radius:10px ;
   padding: 20px ;
   background-color:rgb(255, 190, 50);
}
.sub1{
   width: 100%;
   height:auto;
   border-top-left-radius:10px ;
   border-top-right-radius:10px ;
   padding: 20px ;
   background-color:rgb(255, 190, 50);
   
}
.sub2{
   width: 100%;
   height:auto;
   border-top-left-radius:10px;
   border-top-right-radius:10px;
   padding: 20px ;
   background-color:rgb(255, 190, 50);
}
.headerSub ion-icon,.sub1  ion-icon, .sub2 ion-icon{
   font-size: 50px;
   color: rgb(71, 71, 71);
}
.sub h3{
   font-size:28px;
   margin-top: 18px;
   color: rgb(255, 167, 66);
}
.sub .listSub{
   list-style: none;
   margin-top:10px;
   font-size: 18px;
   color: rgb(59, 59, 59);
}
.sub .buttonSub{
   background-color:rgb(255, 190, 50);
   height: 35px;
   color: black;
   margin: 20px auto;
}
.sub .btnSub1{
   background-color:rgb(255, 190, 50);
   height: 35px;
   color: black;
   margin: 20px auto;
}

.containerSubs .sub .btnSub1,
.containerSubs .sub .btnSub2,
.containerSubs .sub .buttonSub{
   width: auto;
   padding: 2px 5px;
   height: 35px;
   border-radius: 5px;
   border: none;
   font-size: 20px;
   color: rgb(41, 41, 41);
   cursor: pointer;
}

.containerSubs .sub .btnSub1:hover{
  background-color:rgb(250, 181, 33);

}
.containerSubs .sub .btnSub2:hover{
  background-color:rgb(255, 182, 26);

}
.containerSubs .sub .buttonSub:hover{
  background-color:rgb(252, 183, 34)
   

}

.sub .btnSub2{
   background-color:rgb(255, 190, 50);
   height: 35px;
   color: black;
   margin: 20px auto;
}
