
.sectionCafeterias{
    width:100vw;
    height:auto;
   


}
.searchImg{
    width:80%;
    background: url('https://perfectdailygrind.com/es/wp-content/uploads/sites/2/2022/05/image4-15.png');
    ;
    background-size: 100% 100%;
    background-repeat: no-repeat;
   
    overflow: hidden;
    border-radius: 20px;
    height: 340px;
    margin:20px auto 20px;
    
}
.containerSearch{
    width: 100%;
    margin: 20px auto;
    height:100%;
    overflow: hidden;
    text-align: center;
    padding-top:10%;
    z-index: -1;
    ;
   
}
.containerSearch h3{
    font-size:33px;
    border-top: solid rgb(63, 63, 63);
    width: 60%;
    margin: 0 auto;
    color: rgb(255, 197, 88); 
}
.containerSearch h5{
    font-size:22px;
    
    width: 60%;
    margin: 0 auto;
    color: rgb(228, 228, 228); 
}
.containerInput{
    
    width: 80%;
    margin: 10px auto;
    display: flex;
   
   
    justify-content: center;
}
.containerInput input{
    width: 65%;
    border-radius: 10px;
    height: 45px;
    border: none;
    background-color:white;
    font-size: 20px;
    padding-left: 5px;
    outline: none;
    
}

.searchImg .filter{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    backdrop-filter: blur(2px);
    background-color: rgba(56, 56, 56, 0.253);
    overflow: hidden;
}
.itemsContainer{
    width: 85%;
    margin: 20px auto;
    border-top: 2px solid rgb(252, 220, 39);
    display: flex;
    justify-content: center;
   
    
   
    min-height: 20vh;
    display: flex;
    padding:20px;
    gap: 40px;
}
.itemsContainer .filter{
    width: 30%;
    height: auto;
    background-color: rgba(194, 194, 194, 0);
    border-radius: 20px;
    padding: 10px;
     box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.144);
   
}
.itemsContainer .container{
    width: 70%;
    max-width: 850px;
    
    max-height: 620px;
    background-color: rgba(194, 194, 194, 0);;

    padding: 10px 15px ;
    border-radius: 20px;
    overflow: auto;
 
    
}
.containerImg{
    width: 85%;
    margin: 30px auto;
    border-top: solid rgb(250, 199, 32);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0px 40px;
    

}
.containerImg .divImg {
    overflow: hidden;
    margin: 20px;
    border-radius: 50%;
    outline: 2px solid rgba(253, 172, 50, 0.918);
    
}
.containerImg .divImg img {
    width: 200px;
    height: 200px;
    transition: all 0.2s;
    transform: scale(1.4)
    
}
.containerImg .divImg img:hover{

    transform: scale(1.6);
   
    
}

.containerImg h2{
    width: 100%;
    font-size: 27px;
    margin:  10px 0px 40px 0;
    color: rgb(255, 255, 255);
}

.whatsapp {
    border-radius:10px;
    position:fixed;
    z-index: 1;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
 
 }


.whatsapp:before,
.whatsapp:after{
    content: "";
    position: absolute;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    bottom: 5px;
    right: 0px;
    opacity: 0;
    background-color: rgb(50, 255, 136);
    animation: onda 1.5s infinite;
}
.whatsapp:before{
    animation-delay: 1s;
}
.whatsapp:after{
    animation-delay: 1.3s;
}
.whatsappImg{
    position: relative;
    width: 60px;
    height: 60px;
    z-index: 2;
   
}
@keyframes onda {
    0%{
        transform: scale(1);

    }
    15%{
        opacity: 1;

    }
    100%{
        transform: scale(1.5);
        opacity: 0;
    }
}


