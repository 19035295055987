.cn{
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #ffa844;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container{
  width: 1000px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.container .card{
  position: relative;
}

.container .card .face{
  width: 300px;
  height: 200px;
  transition: 0.5s;
}

.container .card .face.face1{
  position: relative;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
}

.container .card:hover .face.face1{
  background: #ffa844;
  transform: translateY(0px);
}

.container .card .face.face1 .content{
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 0.2;
  transition: 0.5s;
}

.container .card:hover .face.face1 .content{
  opacity: 1;
}

.container .card .face.face1 .content .imgface{
  max-width: 100px;
}

.container .card .face.face1 .content .h3face{
  margin: 10px 0 0;
  padding: 0;
  color: #fff;
  align-items: center;
  font-size: 1.5em;
}

.container .card .face.face2{
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(0,0,0,0.8);
  transform: translateY(-100px);
}

.container .card:hover .face.face2{
  transform: translateY(0px);
}

.container .card .face.face2 .content .pface{
  margin: 0;
  margin: 0;
}

.container .card .face.face2 .content .boton {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 40px;
	background: #333;
	color: #fff;
  margin: 20px 0 0;
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	font-weight: 500;
	border: none;
	cursor: pointer;

	transition: .3s ease all;
	border-radius: 5px;
	position: relative;
	overflow: hidden;
}

.container .card .face.face2 .content .boton .spanface{
	position: relative;
	z-index: 2;
	transition: .3s ease all;
}


.container .card .face.face2 .content .boton.buttons::after {
	content: "";
	width: 1px;
	height: 1px;
	background: none;
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	transition: .3s ease-in-out all;
	border-radius: 100px;
	transform-origin: center;
}

.container .card .face.face2 .content .boton.buttons:hover::after {
	transform: scale(400);
	background: #ffa844;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .container .card {   
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
