.menu{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.title{
    color: #854040;
    font-size: 1.5rem;
    margin: 10px;
}

table{
    width: 85%;
    margin: 5px;
}

.subTitle{
    color: #ebeff5;
    background-color: #854040;
    font-size: 25px;
    display: flex;
    justify-content: center;
}

.row{
    background-color: #f0f8ff;
    width: 90vw;
    display: flex;
    flex-direction: column;
}

.item{
    width: 100%;
    height: 40px;
    font-size:20px;
    padding: 5px;
    display: flex;
    justify-content:space-between;
    background-color: aliceblue;
}