.gridContainer {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 10px;
}

.product {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: aliceblue;
  font-size: 1.2rem;
  padding: 10px;
}

.image {
  width: 90%;
  height: 75%;
  border-radius: 10px;
}
