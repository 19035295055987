.cn{
    height: 100vh;
    max-height: 100vh;
    display: flex;
    padding: 30px;
    align-items: center;
    justify-content: space-around;
    background: #ffffff00;
    overflow: hidden;
  }
  .iconX> a ion-icon{
    font-size: 30px;
    cursor: pointer;
    color: black;
}
  
.iconX{
    position: fixed;
    top: 0;
    right: 0;
    padding-left: 20px;
    padding: 20px;
}
.ProductsContainer{
    width: 75%;
    min-height: 430px;
    height: auto;
    max-height: 80vh;
    margin-top:30px ;
    padding: 30px 10px;
    overflow: auto;
    display: flex;
    background-color: #ffffff;
    gap: 25px;
    justify-content: space-around;
    flex-wrap: wrap;
    border-radius: 20px;
  }
  .titleContainerProducts{
  
    font-size: 35px;
    transform: translateY(-100px);
    position: fixed;
    background-color: #fcfcfc00;
    /* border-bottom: 2px solid gray; */
    z-index: 2;
    height: 38px;
    border-bottom-left-radius: 00px;
    border-bottom-right-radius: 00px;
    text-align: center;
  }
  .container{
    width: 100%;
    height: auto;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .card {
    width: 140px;
    height: 204px;
    background: #ffb921;
    overflow: hidden;
    border-radius: 15px;
 /*    box-shadow: 1px 5px 60px 0px #ffa42e; */
   }
   
   .card .cardBorderTop {
    width: 60%;
    height: 3%;
    background: #3d3d3d;
    margin: auto;
    border-radius: 0px 0px 15px 15px;
   }
   
   .card span {
    font-weight: 600;
    color: white;
    text-align: center;
    display: block;
    padding-top: 3px;
    font-size: 18px;
    max-width: 100%;
    overflow: hidden;
   }
   
   .card .job {
    font-weight: 1000;
    color: rgb(80, 80, 80);
    display: block;
    text-align: center;
    padding-top: 10px;
    font-size: 14px;
   }
   
   .card .img {
    width: 100px;
    height: 80px;
    background: #6b64f3;
    border-radius: 15px;
    margin: auto;
    margin-top: 15px;
    overflow: hidden;
    
   }
   .image{
    width: 100px;
    height: 80px;
    object-fit: fill;
    
   }
   
   
   .card >.button {
    padding: 8px 25px;
    display: block;
    margin: auto;
    border-radius: 8px;
    border: none;
    margin-top: 15px;
    background: #414141;
    font-weight: 600;
    cursor: pointer;
   }
   
   .card .button:hover {
    background: #ffffff;
    transition: all .3s;
   }
   .card:hover  .img{
    animation: mover 0.2s ease;
   }
   .button>a{
    color:#ffffff;

   }
   .button:hover>a{
    color: rgb(253, 195, 34);
   }

  @keyframes mover {
    25%{
      transform: translateX(-10px);

    }
    50%{
      transform: translateX(0px);

    }
    75%{
      transform: translateX(10px);

    }
    100%{
      transform: translateX(0px);

    }
    
    
  }