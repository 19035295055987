.main {
  width: 100vw;
  height: auto;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* position: relative; */
  top: 101px;
  z-index: 1;
}

.imgInicio {
  width: 100vw;
  height: 80vh;
  margin: auto 2px;
  background-image: url('https://i.pinimg.com/originals/d6/49/48/d64948463ed288a6105c49177316ef1e.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.filter {
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.347);
  display: flex;
  text-align: center;
  font-size: 1.2rem;
  color: #fff;
}

.content {
  width: auto;
  height: auto;
  margin: auto 4px;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.title2 {
  width: 100%;
  height: 80px;
  color: #ffb61a;
}

.text{
  width: 90%;
  height: auto;
  text-align: center;
  margin-bottom: 20px;
}

.btnStart {
  width: 350px;
  height: 60px;
  border: none;
  margin:20px 0 0 0px;
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #fda623;
  transition: all 1000ms;
  font-size: 15px;
  overflow: hidden;
  outline: 2px solid #fda623;
  background-color: #ffffff;
}

.btnStart:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid #ffcc23;
  box-shadow: 4px 5px 17px -4px #ffb923;
  cursor: pointer;
}

.btnStart::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #ffc421;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}

.btnStart:hover::before {
  width: 250%;
}

@keyframes show {
  0%{
      transform: translateY(-1000px)
  }
  100%{
      transform: translateX(0px)

  }
  
}

.infoGray {
  width: 100%;
  height: 700px;
  background: #343434;
  background: linear-gradient(250deg, #141414, #086b72);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 40px;
  gap: 40px;
}

.infoGray img {
  width: 100%;
  border-radius: 20px;
}

.infoGray .containerInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* gap: 10px; */
}

.infoGray .title3 {
  font-size: 25px;
  padding-top: 20px;
  color: #ffb61a;
  text-align: center;
}

.infoGray .containerInfo .text {
  text-align: justify;
  width: 100%;
  font-size: 20px;
  color: #c7c7c7;
  border-bottom: solid #adff2f;
  padding: 10px 0 15px 0px;
}

.infoGray .containerInfo .title5 {
  width: 60%;
  font-size: 1rem;
  color: #000000;
}

.ContainerTarjetas{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 25px;
}

.infoTitle{
  width: 100%;
  text-align: center;
  color: #2e2d31;
  font-size: 50px;
}

.itemInfo{
  width: 300px;
  height: 400px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  padding: 40px 10px;
  text-align: center;
  box-shadow: 0px 4px 28px 2px rgb(190, 190, 190);
}

.itemInfo>h2{
  color: #fda623;
}

.itemInfo>p{
  font-size: 18px;
  color: #636363;
}

.itemImg{
  width: 100px;
  margin: 0 auto;
  height: 80px;
}

.brands{
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  padding: 20px;
  margin-bottom: 20px;
}

.carusel{
  display: flex;
  gap: 55px;
  width: max-content;
  animation: carousel-animation 18s infinite linear ;
}

.brand{
  width: 200px;
  height: 200px;
  padding: 20px;
  border-radius: 20px;
  background-color: #ffffff;
}

.brand>img{
 width: 100%;
 height: 100%;
}

@keyframes carousel-animation{
  0% {
    transform: translateX(-52%);
  }
  100% {
    transform: translateX(-1%);
  }
} 