.itemCafeterias {
  width: 100%;
  height: 210px;
  display: flex;
  background-color: rgba(255, 255, 255, 0);
  margin-top: 20px;
  box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.144);
  border-radius: 20px;
}

.itemCafeterias .imgItem {
  width: 42%;
  overflow: hidden;
}

.itemCafeterias .imgItem .img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.itemCafeterias .infoItem {
  width: 58%;
}

.itemCafeterias .infoItem .title {
  padding: 10px 10px 0px 10px;
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
}

.itemCafeterias .infoItem .title .h4 {
  color: rgb(255, 168, 37);
  font-size: 22px;
  cursor: pointer;
  transition: all 0.8s;
}

.itemCafeterias .infoItem .title .h4:hover {
  text-decoration-line: underline;
}

.itemCafeterias .infoItem .title ion-icon {
  font-size: 30px;
  color: rgb(248, 2, 105);
}

.itemCafeterias .infoItem .h5 {
  font-size: 17px;
  padding: 0px 10px;
  text-decoration-line: underline;
  color: rgb(0, 132, 255);
  cursor: pointer;
}

.itemCafeterias .infoItem .p {
  font-size: 16px;
  min-height: 70px;
  overflow: hidden;
  padding: 5px 10px;
  color: rgb(221, 221, 221);
}

.itemCafeterias .infoItem .titleBottom {
  padding: 15px 0px;
  padding-right: 10px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
}

.itemCafeterias .infoItem .titleBottom .h5 {
  padding: 8px 10px 0 10px;
  text-decoration-line: underline;
  font-size: 14px;
  color: rgb(163, 163, 163);
}

.itemCafeterias .infoItem .titleBottom .ranking {
  width: 18%;
  font-size: 15px;
  text-align: center;
  padding-top: 5px;
  border-radius: 10px;
  background-color: rgb(255, 252, 47);
}
