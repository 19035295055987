.main {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 99px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
  background: rgb(255, 168, 68);
}

.phone {
  width: 100%;
  height: 88%;
  padding: 10px 20px;
  border-radius: 32px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0px 17px 20px rgba(0, 0, 0, 0.15);
  overflow: auto;
}

.phoneHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 45px;
}

.phoneHead .title {
  font-size: 24px;
  font-weight: 500;
  color: #34394f;
}

.grad {
  background: linear-gradient(90deg, #d13eea, #53d9ea);
  color: white;
}

.chat {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid #d3d3d35e;
  cursor: pointer;
}

img.chatAvatar {
  width: 85px;
  height: 65px;
  box-shadow: 1px 6px 18px rgba(31, 37, 72, 0.45);
  border-radius: 7px;
  border-radius: 7px;
  margin-right: 8px;
}

.contactName {
  font-weight: 500;
  color: #34394f;
  font-size: 19px;
  margin-bottom: 2px;
}

.contactMsg {
  font-size: 14px;
  color: #a5a5a5;
  font-weight: lighter;
}

.chatInfo {
  width: 70%;
  height: 80%;
}

.chatDate {
  font-size: 12px;
  color: #5a5a5a;
  margin-bottom: 2px;
}

.chatNew {
  padding: 2px 5px;
  font-size: 11px;
  border-radius: 2px;
}

.chatStatus {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.phoneFooter {
  position: absolute;
  bottom: 0;
  height: 61px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0px;
  background: linear-gradient(0deg, white 40%, #ffffffa1 85%, transparent 95%);
}

.footerDivider {
  height: 5px;
  width: 45%;
  border-radius: 10px;
  margin-top: 35px;
}
