.itemCafeterias {
  width: 100%;
  height: 180px;
  display: flex;
  background-color: #fff;
  margin-top: 20px;
  align-items: center;
  box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.144);
  border-radius: 20px;
}

.imgItem {
  position: relative;
  width: 42%;
  height: 100%;
  overflow: hidden;
}

.img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.itemCafeterias .infoItem {
  width: 58%;
}

.itemCafeterias .infoItem .title {
  padding: 10px 10px 0px 10px;
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
}

.itemCafeterias  .title .h4 {
  color: rgb(255, 168, 37);
  font-size: 22px;
  cursor: pointer;
  transition: all 0.8s;
}

.itemCafeterias .infoItem .title .h4:hover {
  text-decoration-line: underline;
}

.itemCafeterias .infoItem .title ion-icon {
  font-size: 30px;
  color: rgb(248, 2, 105);
}

.itemCafeterias .infoItem .h5 {
  font-size: 17px;
  padding: 0px 10px;
  text-decoration-line: underline;
  color: rgb(0, 132, 255);
  cursor: pointer;
}

.itemCafeterias .infoItem .p {
  font-size: 16px;
  min-height: 70px;
  overflow: hidden;
  padding: 5px 10px;
  color: #aaaaaa;
}


