.sectionCafeterias {
    width: 100vw;
    height: auto;
    position: relative;
    top: 131px;
    bottom: 1400px;
  }
  
  .searchImg {
    width: 95%;
    background: url("https://perfectdailygrind.com/es/wp-content/uploads/sites/2/2022/05/image4-15.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    border-radius: 20px;
    height: 350px;
    margin: 0 auto 20px;
  }
  
  .searchImg .filter {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    backdrop-filter: blur(3px);
    background-color: rgba(56, 56, 56, 0.219);
    overflow: hidden;
  }
  
  .container {
    width: 95%;
    height: 620px;
    margin: 0 auto 20px;
    background-color: rgba(206, 206, 206, 0);
    box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.144);
    padding: 10px 15px;
    border-radius: 20px;
    overflow: auto;
  }
  
  .grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 2px auto;
  }
  
  .containerImg{
    width: 95%;
    /* background-color: #fff; */
    margin: 30px auto;
    border-top: solid rgb(52, 189, 253);
  display: flex;
    flex-wrap: wrap;
    padding: 0px 40px;
  }
  
  .containerImg .h2{
    width: 100%;
    text-align: center;
    margin:  10px 0px 40px 0;
    color: rgb(195, 255, 56);
  }