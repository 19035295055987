
.header{
    width: 100vw;;
    display: flex;
    padding-top: 100px;
    background-color: rgb(131, 54, 54);
    height: 200px;
}
.logo{
    width:30% ;
    height: 100px;
    display: flex;
    align-items: center;

}
.logo img{
    width: 180px;
    height: 100px;
}
.logo h3{
    font-size: 30px;
    width: 65%;
    color: rgb(255, 186, 121);
}
.ul{
    width: 70%;
    height: 100%;
    display: flex;
    list-style: none;
    padding-right: 80px;
    justify-content: flex-end;
    align-items: center;
}
.li,.li a {
    font-size: 25px;
    color: rgb(58, 58, 58);
    cursor: pointer;
    width: auto;
    display: flex;
   align-items: center;
    height: 100%;
    transition: all .4s;
    color: bisque;
    padding: 10px;
    
    
}

.li:hover{
    background: rgb(255, 186, 121);
    color: rgb(37, 37, 37);
    border-bottom:2px solid white ;
}