
.itemCafeterias{
    width: 100%;
    height: 210px;;
    display: flex;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 2px 3px 8px 1px rgba(255, 255, 255, 0.144);
    border-radius: 20px;
    
}

.itemCafeterias .imgItem{

    width: 42%;
    height: 210px;
    overflow: hidden;

  
    
}
.itemCafeterias .imgItem .img{
    
    width:100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 100%;

   
    
}
.itemCafeterias .infoItem{
    width: 58%;
    height: 100%;
    overflow: hidden;
    
}
.itemCafeterias .infoItem .title{
    padding: 5px 10px 0px 10px;
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    

}
.itemCafeterias .infoItem .title h4{
    width: 100%;
    overflow: hidden;
    color: rgb(255, 168, 37);
    font-size:24px;
    transition: all 0.8s;

}
.itemCafeterias .infoItem .title h4:hover{
     
   text-decoration-line: underline;


}
.itemCafeterias .infoItem .title ion-icon{
    font-size:30px;
    color: rgb(250, 30, 49);
}
.itemCafeterias .infoItem  h5{
    font-size:19px;
    max-width: 90%;
    height: 45px;
    max-height: 45px;
    overflow: hidden;
    padding: 0px 10px;
    
   text-decoration-line: underline;
   color: rgb(0, 132, 255);
   cursor: pointer;
}
.itemCafeterias .infoItem  p{
    font-size:17px;
    height: 70px;
    max-height: 70px;
    overflow: hidden;

    padding: 5px 10px;
    color: rgb(83, 83, 83);
}
.itemCafeterias .infoItem .titleBottom{
    padding: 0px 0px 10px 0px;
    padding-right: 10px;
    width: 100%;
    height: 60px;;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

}
.itemCafeterias .infoItem .titleBottom {
    padding:0px 10px 0 10px;
    font-size: 18px;
    color: rgb(117, 117, 117);

}
.containerImgComment{
    width: 200px;
    height: 45px;
    padding-left: 8px;
    
    display: flex;
    background-color: rgba(165, 42, 42, 0);
    align-items: flex-end;
    

}
.containerImgComment:nth-child(1){
    z-index:1;

}
.containerImgComment:nth-child(2){
    z-index:2;

}
.containerImgComment:nth-child(3){
    z-index:3;

}
.containerImgComment:nth-child(4){
    z-index:4;

}
.containerImgComment:nth-child(5){
    z-index:5;

}
.containerImgComment:nth-child(6){
    z-index:6;

}
.imgComment{
    border-radius: 50%;
    height: 35px;
    width: 35px;
    position:relative;
    margin-left: -8px;
    background-color: #ffffff;
    
    outline:2px solid rgb(255, 255, 255);
    
}
.imgCommentPlus{
    background-color: #ffb324;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    position:relative;
    margin-left: -8px;
    text-align: center;
    padding-top:7px ;

}

.button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    transform: scale(0.9);
   }
   
   .button.learn-more {
    width: 140px;
    height: auto;
   }
   
   .button.learn-more .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #ffb324;
    border-radius: 1.625rem;
   }
   
   .button.learn-more .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
   }
   
   .button.learn-more .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
   }
   
   .button.learn-more .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
   }
   
   .button.learn-more .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: #585858;
    font-weight: 100;
    line-height: 1.6;
    text-align: center;

   }
   
   .button:hover .circle {
    width: 100%;
    
   }
   
   .button:hover .circle .icon.arrow {
    background:white;

    border-color: #fcd83c;
    transform: translate(1rem, 0);
   }
   
   .button:hover .button-text {
    color: #333333;
   }
   
