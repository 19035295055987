.containerFeatured{
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutus{
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-color: #f8f5f5;
    /* justify-content: center; */
}

.title{
    color: #d6b877;
    font-size: 2rem;
    display: flex;
    justify-content: center;
}

.subtitle{
    color: #ffd399; 
    font-size: 1.5rem;
}

.imgMain{
    width: 70%;
    height: auto;
    display: flex;
    justify-content: center;
}

.imgReference{
    height: auto;
    width: 100%;
}

.txt{
    width: 80%;
    color:#808080;
    margin-bottom: 10px;
    text-align: justify;
}

.btn{
   background-color: #914646;
   color: #ffffff;
   width: 100px;
   height: auto;
   font-size: 1rem;
   border-radius: 30px;
   margin-bottom: 20px;
}
