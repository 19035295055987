.footer{
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  background-color: #353535;
}

.logo{
  width: 180px;
  height: 180px;
}

.footerContainer{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  color: #fff;
  text-align: center;
  font-size: 15px;
}

.footerContactUs{
  width: 50%;
}

.h3{
  font-size: 1.2rem;
}

.p{
  margin-top: 10px;
}

.footerLegalInformation{
  width: 50%;
}

.footerIcons{
  width: 80%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
}

.footerIcon{
  width: 30px;
  height: 30px;
  color: rgb(253, 189, 69);
}