
.sectionCafeterias{
  width:100vw;
  height:auto;
 


}
.searchImg{
  width:80%;
  background: url('https://perfectdailygrind.com/es/wp-content/uploads/sites/2/2022/05/image4-15.png');
  ;
  background-size: 100% 100%;
  background-repeat: no-repeat;
 
  overflow: hidden;
  border-radius: 20px;
  height: 340px;
  margin:20px auto 20px;
  
}

.searchImg .filter{
  width: 100%;
  max-height: 100%;
  border-radius: 20px;
  backdrop-filter: blur(3px);
  background-color: rgba(56, 56, 56, 0.219);
  overflow: hidden;
}
.itemsContainer{
  width: 85%;
  margin: 20px auto;
  border-top: solid rgb(39, 156, 252);
  display: flex;
  justify-content: space-between;
 
  
 
  min-height: 20vh;
  display: flex;
  padding:20px;
  gap: 40px;
}
.itemsContainer .filter{
  width: 30%;
  height: 400px;
  background-color: rgba(194, 194, 194, 0);
  border-radius: 20px;
  padding: 10px;
   box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.144);
 
}
.itemsContainer .container{
  width: 70%;
  max-width: 850px;
  
  max-height: 620px;
  background-color: rgba(194, 194, 194, 0);;
  box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.144);
  padding: 10px 15px ;
  border-radius: 20px;
  overflow: auto;

  
}
.containerImg{
  width: 85%;
  margin: 30px auto;
  border-top: solid rgb(52, 189, 253);
  display: flex;
  flex-wrap: wrap;
  padding: 0px 40px;
  

}
.containerImg div {
  overflow: hidden;
  margin: 20px;
  border-radius: 50%;
  outline: 2px solid rgba(84, 142, 252, 0.918);
  
}
.containerImg div img {
  width: 200px;
  height: 200px;
  transition: all 0.2s;
  
}
.containerImg div img:hover{

  transform: scale(1.2);
 
  
}

.containerImg h2{
  width: 100%;
  margin:  10px 0px 40px 0;
  color: rgb(195, 255, 56);
}


.containerSubs{
 /*
* Created with https://www.css-gradient.com
* Gradient link: https://www.css-gradient.com/?c1=343434&c2=066369&gt=l&gd=dcl
*/
background: rgba(52, 52, 52, 1.0);
background: linear-gradient(300deg, rgba(52, 52, 52, 1.0), rgba(66, 57, 155, 0.911));
  width: 100%;
  height: auto;
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap:80px;
}

.sub{
  width: 20%;
  height:auto;
  background-color:rgba(100, 100, 100, 0.164);
  border-radius: 10px;
  text-align: center;
  transform: rotate3d(2, -1, -1, -0.08turn);
  transition: all 0.2s;
}
.sub:hover{
  transform: rotate3d(1, 1, 1, 0turn)
 
}
.headerSub{
  width: 100%;
  height:auto;
  padding: 20px ;
  background-color:rgb(230, 253, 25);
}
.sub1{
  width: 100%;
  height:auto;
  padding: 20px ;
  background-color:rgb(255, 63, 63);
  
}
.sub2{
  width: 100%;
  height:auto;
  padding: 20px ;
  background-color:rgb(236, 81, 241);
}
.headerSub ion-icon,.sub1  ion-icon, .sub2 ion-icon{
  font-size: 50px;
  color: rgb(71, 71, 71);
}
.sub h3{
  font-size:28px;
  margin-top: 18px;
  color: rgb(39, 122, 248);
}
.sub .listSub{
  list-style: none;
  margin-top:10px;
  font-size: 15px;
  color: aliceblue;
}
.sub .buttonSub{
  background-color:rgb(230, 253, 25);
  height: 35px;
  color: black;
  margin: 20px auto;
}
.sub .btnSub1{
  background-color:rgb(255, 63, 63);
  height: 35px;
  color: black;
  margin: 20px auto;
}

.containerSubs .sub .btnSub1,
.containerSubs .sub .btnSub2,
.containerSubs .sub .buttonSub{
  width: auto;
  height: 35px;
  border-radius: 5px;
  border: none;
  font-size: 20px;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.containerSubs .sub .btnSub1:hover,
.containerSubs .sub .btnSub2:hover,
.containerSubs .sub .buttonSub:hover{
  background-color: initial;

}

.sub .btnSub2{
  background-color:rgb(236, 81, 241);
  height: 35px;
  color: black;
  margin: 20px auto;
}
.whatsapp {
  width: 160px;
  background-color:rgb(77, 77, 77);
  height: auto;
  color: rgb(253, 253, 253);
  border-radius:10px;
  padding:8px 5px;
  position:fixed;
  z-index: 1;
  bottom: 20px;
  right: 20px;
  font-size: 17px;
  cursor: pointer;

}
.whatsapp ion-icon{
  font-size:17px;
  margin-right: 5px;
  margin-left: 8px;
  color: rgb(58, 183, 255);
 
}


