.homeCafeteria{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 150px;
    gap: 25px;
    background-color: #f0f8ff;
}

.title{
    color: #d6b877;
}

.line{
    width: 80%;
    border: 3px #d6b877 dotted;
}

.text{
    color:#808080;
    width: 90%; 
    text-align: justify;
}

.containerImg{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgRef{
    width: 90%;
    height: auto;
    margin: 0 auto;
    background-color:#ff0000;
    filter: blur(2px) brightness(80%);
}

.txtImg{
    background-color: #ff000034;
    position: absolute;  
    width: 80%;
    height: auto;
    font-size: 20px;
    color: #fde39a;
}