.containerMenu{
    margin:auto 0;
    padding-top: 100px;
   
    width: 100%;
    height: 350px;
    text-align: center;
    display: flex;
    justify-content: space-around;
   
    
    
   
}
.itemMenu{
  width: 24%;
  height:250px;
  background-color:rgb(41, 41, 41);
  overflow: hidden;



}
.itemMenu:hover>img{
  height:0;
  border:none;
}
.itemMenu:hover>.link{

  
  height:210px;
}
.itemMenu img{
  width: 100%;
  height:210px;
  border-bottom: 2px solid rgb(32, 110, 255);
  transition: all 0.6s;
}
.itemMenu .link{
  margin: 0;
  width: 100%;
  height:35px ;
  transition: all 0.6s;
  padding: 0px 10px;
  color:  rgb(255, 165, 47);
  overflow: hidden;
  text-decoration: none;
}
.itemMenu .link h2{
  color:  rgb(255, 165, 47);
  text-decoration: none;

}
.itemMenu .link p{
  font-size:18px;
  margin-top: 10px;
  color: rgb(223, 223, 223);
}