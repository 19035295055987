.slider {
  width: 100%;
  height: 70vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('./patterns.svg');
}

.slide {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-50%);
  transition: all 0.5s ease;
}

.current {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transform: translateX(0);
}

.card {
  font-size: 1.2rem;
  background-size: cover;
  /* color: #515151; */
}

.content {
  width: 90vw;
  height: 75%;
  position: absolute;
  top: 4.7rem;
  left: 2.5rem;
  right: 3rem;
  margin: auto 2px;
  opacity: 0;
  color: #000;
  font-weight: 600;
  padding: 3rem;
  background: linear-gradient( 300deg,rgba(255, 255, 255, 0.072) 40%,rgb(218, 228, 230) 160%,rgba(255, 255, 255, 0.521) 100% );
  border-radius: 20px;
  border: 0.5px solid rgb(255, 255, 255);
  backdrop-filter: blur(12px);
  animation: slide-up 1s ease 0.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content > * {
  /* color: #000000; */
  margin-bottom: 1rem;
}

.current .content {
  width: 80%;
  opacity: 1;
  transform: translateX(0);
  transition: all 0.5s ease;
}

.slide img {
  width: 100vw;
  height: 80vh;
}

.title{
  color: #ffa742;
}

.text{
  white-space: pre-wrap;
  color: #e9f0f7;
  font-size: 1.2rem;
}

.btnStart {
  width: 250px;
  height: 50px;
  border: none;
  margin:20px 0 0 0px;
  border-radius: 5px;
  letter-spacing: 5px;
  color: #000;
  font-size: 15px;
  overflow: hidden;
  background-color: #fcb722;
}

@keyframes show {
  0%{
      transform: translateY(-1000px)
  }
  100%{
      transform: translateX(0px)

  }
  
}