.section{
    width: 100vw;
    min-height: 50vh;
    height: auto;
    background-color: white;
}
.imgProductsCafeteria{
    width: 100%;
    height: 500px;
    background-image: url('https://perfectdailygrind.com/es/wp-content/uploads/sites/2/2022/07/image2-12.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    filter: brightness(50%);
    position: relative;
    z-index: 0;
}
.container{
    width: 80%;
    min-height: 500px;
    background-color: white;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    margin-top: -200px;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
}
.title{
    font-size:35px;
    width: 80%;
    margin: 10px auto;
    padding-bottom: 15px;
    color: rgb(88, 88, 88);

}
.ProductsContainer{
    width: 100%;
    min-height: 400px;
    height: auto;
    margin-top:30px ;
    display: flex;
    gap: 25px;
    justify-content: space-around;
    flex-wrap: wrap;
}
.ul{
    width: 80%;
    padding: 10px;
    list-style: none;
    height: auto;
    margin: 0 auto;
    display: flex;
    justify-content:space-around;
    border-bottom: 1px solid rgb(199, 199, 199);
}
.li{
    cursor: pointer;
    color: darkred;
    height: auto;
    border-bottom: 2px solid white;
    transition: all .3s;
    font-size: 20px;
}
.li:hover{
    
    border-bottom: 2px solid rgb(77, 77, 77);
}
.ads{
    width: 100%;
    height: 60px;
    background-color: rgba(248, 217, 42, 0.87);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
}
.ads a{
    width: auto;
    animation: ads  infinite ease-in-out;
    animation-delay: 0.1s;
    animation-duration: 20s;
    animation-play-state: running;
    color: rgb(68, 68, 68);
}
.ads a:hover{
    animation-play-state: paused;
    text-decoration: underline;
    
}

@keyframes ads {
    0%{
        transform:translateX(-1150px)
    }
    
    100%{
        transform:translateX(1150px)
    }

}