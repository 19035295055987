.select{
    width: 100%;
    background-color: rgb(255, 255, 255);
    height: 40px;
    border-radius: 10px;
    font-size: 20px;
    color: rgb(252, 152, 60);
    border: none;
    outline: none;
    
}
.select:hover{
    outline: solid rgb(67, 149, 255);
    cursor: pointer;
   
}
.check{
    width: 100%;
    margin-top:5px;
    display: flex;
    height: 35px;
    
    align-items:center;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    cursor: pointer;
}
.check input{
    margin-left: 7%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    
}
.check p{
    margin-left: 2%;
    font-size: 17px;
}
.iframe{
    margin-top:50px;
    width: 100%;
    height: 200px;
}
