section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #ffa84400;
}


section .color {
    position: absolute;
    filter: blur(150px);
}

section .color:nth-child(1) {
    top: -350px;
    width: 600px;
    height: 600px;

}

section .color:nth-child(2) {
    top: 150px;
    left: -155px;
    width: 120px;
    height: 120px;
    z-index: 2;
}

section .color:nth-child(3) {
    bottom: 50px;
    right: 100px;
    width: 300px;
    height: 300px;
}

.jk {
    position: relative;
}

.jk .square {
    position: absolute;
    backdrop-filter: blur(5px);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    animation: animate 10s linear infinite;
    animation-delay: calc(-1s * var(--i));
}

@keyframes animate {

    0%,
    100% {
        transform: translateY(-40px);
    }

    50% {
        transform: translateY(40px);
    }
}

.jk .square:nth-child(1) {
    top: -50px;
    right: -95px;
    width: 100px;
    height: 100px;
}

.jk .square:nth-child(2) {
    top: 150px;
    left: -125px;
    width: 120px;
    height: 120px;
    z-index: 2;
}

.jk .square:nth-child(3) {
    bottom: 50px;
    right: -85px;
    width: 80px;
    height: 80px;
    z-index: 2;
}


.jk .square:nth-child(4) {
    bottom: -80px;
    left: 100px;
    width: 50px;
    height: 50px;
}

.jk .square:nth-child(5) {
    top: -80px;
    left: 100px;
    width: 60px;
    height: 60px;
}

.container {
    position: relative;
    max-width: 850px;
    width: 900px;
    background: #fff;
    padding: 40px 30px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    perspective: 2700px;
}

.container .cover {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 50%;
    z-index: 98;
    transition: all 1s ease;
    transform-origin: left;
    transform-style: preserve-3d;
}

.container .cover::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: #262626;
    opacity: 0.5;
    z-index: 100;
}

.container .flip:checked~.cover {
    transform: rotateY(-180deg);
}

.container .flip:checked~.cover .back {
    transform: rotateY(-180deg);
}

.container .flip:checked~.cover .text {
    transform: rotateY(180deg);
}


.container .cover .imgContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 12;
    backface-visibility: hidden;
}

/* .container .cover .back .backImg{
    transform: rotateY(180deg);
}
 */

.container .cover .text {
    position: absolute;
    z-index: 111;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.9s;
    list-style: none;
}

.logoForm {
    width: 200px;
    background-color: rgba(255, 255, 255, 0);
    height: 200px;
    position: relative;
}

.cover .text .text-1,
.cover .text .text-2 {
    font-size: 26px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    backface-visibility: hidden;
}

.cover .text .text-2 {
    font-size: 15px;
    font-weight: 500;
    color: #ffa844;
}


.container form {
    height: 100%;
    width: 100%;
    background: #fff;
}


.container .form-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-content .login-form,
.form-content .signup-form {
    width: calc(100% / 2 - 25px);
}


form .form-content .title {
    position: relative;
    font-size: 24px;
    font-weight: 500;
    color: #000;
}

form .form-content .title::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 25px;
    background: #262626;
}

.iconX>a ion-icon {
    font-size: 30px;
    cursor: pointer;
    color: black;
}

.iconX {
    position: fixed;
    top: 0;
    right: 0;
    padding-left: 20px;
    padding: 20px;
}


form .signup-form .title:before {
    width: 20px;
}

form .form-content .input-boxes {
    margin-top: 30px;
}

form .form-content .input-box {
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    margin: 10px 0;
    position: relative;
}

.type {
    display: none;
}

.form-content .input-box input,
.button-form,
.buttonDiv-form {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    padding: 0 30px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.form-content .input-box input,
.button-form:focus,
.form-content .input-box input,
.button-form:valid {
    border-color: #ffa844;
}

.form-content .input-box i {
    position: absolute;
    color: #ffa844;
    font-size: 17px;
}

form .form-content .text {
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

form .form-content .text a {
    text-decoration: none;
    color: #000;
}

form .form-content .text a:hover {
    text-decoration: underline;

}

form .form-content .button {
    color: #fff;
    margin-top: 40px;
}

form .form-content .button input {
    color: #fff;
    background: #0004ff;
    border-radius: 6px;
    padding: 0;
    cursor: pointer;
    transition: all 0.4s ease;
}

form .form-content .button-form,
.buttonDiv-form {
    color: #fff;
    background: #262626;
    border-radius: 6px;
    padding: 0;
    cursor: pointer;
    transition: all 0.4s ease;
}

form .form-content .button-form:hover,
.buttonDiv-form:hover {
    background: #ffa844;
}

form .form-content .button input:hover {
    background: #00b3ff;
}

form .form-content label {
    color: #0004ff;
    cursor: pointer;
}

form .form-content label:hover {
    text-decoration: underline;
}

form .form-content .login-text,
form .form-content .signup-text {
    text-align: center;
    margin-top: 25px;
}

.input-boxx {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #ffa844;
    height: 500px;
    width: 500px;
    cursor: pointer;
    border-radius: 5px;
}

.input-boxes .container-file {
    display: flex;
    justify-content: center;
}

.form-content .input-boxx i {
    position: absolute;
    color: #ffa844;
    font-size: 17px;
}

.image-preview {
    position: relative;

}

.image-preview .imgPreview {
    display: inline-block;
    max-width: 100%;
    height: 125px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.image-preview .btnPreview {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
}

form .form-content .input-boxx {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 130px;
    width: 130px;
    margin: 10px 0;
}

.container .flip {
    display: none;
}

@media (max-width: 730px) {
    .container .cover {
        display: none;
    }

    .form-content .login-form,
    .form-content .signup-form {
        width: 100%;
    }

    .form-content .signup-form {
        display: none;
    }

    .container .flip:checked~form .signup-form {
        display: block;
    }

    .container .flip:checked~form .login-form {
        display: none;
    }
}