.main {
  width: 100%;
  padding-top: 110px;
}

.adminContainerCreateProduct {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  padding-top: 50px;
}

.btnAdminCreateProduct {
  width: 65%;
  height: 60px;
  font-size: 1.2rem;
  border-radius: 10px;
}

.containerForm {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 100px;
  background: radial-gradient(at center bottom,rgb(77, 77, 77), rgb(36, 36, 36) );
  margin-bottom: 50px;
  z-index: 3;
}

.iconCLose {
  width: 50px;
  height: 50px;
  position: absolute;
  color: #fff;
  right: 10px;
  top: 10px;
  z-index: 4;
}