.footer {
    width: 99vw;
    height: 180px;
    background-color: #353535;
    display: column;
    
}
.footer ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    
    align-items: center;
    gap: 10px;
    list-style: none;
   
    
}
.footer ul li h3{
    margin-bottom:14px;
    color: rgb(253, 152, 69);

}
.footer ul li p{
    margin-bottom:5px
    

}
.footer ul li p span{
    margin-left: 10px;
    color: #a5a5a5;

}
.footer ul li{
    width: 18%;
    height: auto;
    color: #fff;

    
    
}
.footer ul li img{
    
    width: 150px;
    height: 150px;
    color: rgb(202, 255, 96);

    border-right: solid rgb(255, 167, 66);
    
}
.footer ul li .icons{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
   
}
.footer ul li .icons ion-icon,.footer ul li .iconsCreators ion-icon{
    width: 40px;
    padding: 5px;
    height: 40px;
    font-size:30px;
    margin: 5px;
    border:none;
    background-color:rgb(0, 183, 255);
    border-radius:50%;
    cursor: pointer;

}

.footer ul li .icons ion-icon:nth-child(1){
    background-color:rgb(24, 152, 202);
    color: #fff;

}
.footer ul li .icons ion-icon:nth-child(2){
    background-color:rgb(255, 255, 255);
    color: #fa2424;

}
.footer ul li .iconsCreators ion-icon:nth-child(1){
    background-color:rgb(255, 255, 255);
    color: #fd2542;

}


.footer ul li .iconsCreators ion-icon:nth-child(3){
    background-color:rgb(255, 255, 255);
    color: #fded06;

}
.footer ul li .icons ion-icon:nth-child(3){
    background-color:rgb(255, 255, 255);
    color: #327aff;

}
.footer ul li .iconsCreators ion-icon:nth-child(2){
    background-color:rgb(48, 48, 48);
    color: #32adff;

}





