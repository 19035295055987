.sectionCarta{
    width: 100vw;
    height:auto;
    background:white;
}
.imgCarta{
    width: 80%;
    margin: 0 auto;
    height:300px;
    overflow: hidden;
    display: flex;
    position: relative;
    text-align: center;
}
.imgCarta h3{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin: 0px auto;
    padding-top: 100px;
    backdrop-filter: brightness(30%);
    color: aliceblue;
    font-size: 28px;
}
.imgCarta h2{
    font-size: 35px;
    position: absolute;
    color: orange;
    z-index: 3;
    width: 100%;
    margin: 50px auto;

}
.containerCarta{
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.itemCarta{
    width: 400px;
    height: auto;
    margin-top: 20px;
}
.titleCarta{
    width: 100%;
    font-size:30px;
    text-align: center;
    padding:5px;
    color: aliceblue;
    background-color: rgb(133, 64, 64);
}
.itemPriceOne{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content:space-between;
    padding: 5px;
    font-size:20px;
    background-color: aliceblue;
}
.itemPricetwo{
    width: 100%;
    display: flex;
    height: 40px;
    justify-content:space-between;
    padding: 5px;
    font-size:20px;
    background-color: rgb(243, 243, 243);
}

